import { createSlice } from "@reduxjs/toolkit";



export const fileSlice = createSlice({
    name : "file",
    initialState:{
        files : {}, 
    },
    reducers: {
        addFile : (state, action) => {
           
            state.files = {...state.files, [ action.payload.varName]:  action.payload.fileName }
            return state
        },
    }
})


export const {addFile} = fileSlice.actions;