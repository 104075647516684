import React from 'react';
import { Buttons } from '../buttons/Buttons';



export const ButtonInLine = (props) => {

    const containerStyle =  {
        marginLeft:'auto',
        marginRight:'auto',
        width:'30%',
        display: 'flex',
        justifyContent: 'center',
        ...props.style,
    }

    return(
        <div class="btn my-2 my-sm-0">
            <Buttons text = {props.text} click = {props.click} isDefine = {true}/>
        </div>
    )

}