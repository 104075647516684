import React  from 'react';


export const TextArea = (props) => {

    const textAreaStyle = {
        minWidth : "90%",
        ...props.style
    }

    return (
        <textarea rows={10} cols = {150} style = {textAreaStyle} spellCheck="false" defaultValue = {props.text} /> 
    

    )
}