import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setDash} from '../redux/connection/log.js'
import {createSearchParams, useNavigate, useSearchParams} from "react-router-dom";

import { Deconnection } from '../deconnection/Deconnexion.js';
import { ButtonInLine } from '../stores/buttonInLine/ButtonInLine.js';
import { internalDiv } from '../styles/internalDiv.js';
import { BarreNav } from '../barreNav/barreNav.js';
import { TextArea } from '../stores/textarea/TextArea.js';
import { getAPI, putAPI } from '../api/api.js';
import { setMsg } from '../redux/test/test.js';


export const Dashboard = (props ) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const tokenRefresh = useSelector(state => state.log.tokenRefresh)
    const test = useSelector(state => state.test)

    const [mongo, setMongoData] = useState("")
    const [mongoAll, setMongoAllData] = useState("")


    useEffect( () => {
        if (searchParams.has('t') || searchParams.has('sso')) {
            let newParams = []
            setSearchParams(newParams);
            navigate({
                search: createSearchParams(newParams).toString(),
            }, {replace: true});
        } 
        dispatch(setDash(false)) 
    }, [])

    const handle = () => {
        getAPI("/userinfos", tokenRefresh).then(datas=> {
            if (datas.ok) {
                console.log(datas)
                dispatch(setMsg({infos:datas.data}))//redux tool kit permettra à d'autres composant d'accéder à ces valeurs
            }
        })
    }


    const handleMongo = () => {
        const data = {
            action : "fetch",
            code : "23703-2"
        }
        putAPI("/testmongo", tokenRefresh, data).then(datas=> {
            if (datas.ok) {
                console.log(datas)
                setMongoData(JSON.stringify(datas.data)) //le stockage dans un etat (state) 'simple', hors redux, confine l'accès à ce seul composant 
            }
        })
    }


  


    return (
        <div style={{...internalDiv.mainContainerStyle, overflow : 'auto'}}>
            <BarreNav />
          
            <div style={internalDiv.containerStyle}>
                <div style = {{display : 'flex', height:'80%', flexDirection : 'column', gap :'20px'}}>
                    <div style = {{display: 'flex', width : '50%', heigth : '20%', justifyContent:'center', alignItems:'center', marginLeft: 'auto', marginRight:'auto', gap : '10px'}} >
                        <ButtonInLine
                            text = "Test"
                            click = {handle}
                        />
                        <TextArea 
                            text = {test.msg}
                        />
                    </div>

                    <div style = {{display: 'flex', width : '50%',heigth : '20%', justifyContent:'center', alignItems:'center', marginLeft: 'auto', marginRight:'auto', gap : '10px'}} >
                        <ButtonInLine
                            text = "Test Mongo"
                            click = {handleMongo}
                        />
                        <TextArea 
                            text = {mongo}
                        />
                    </div>
       

                </div>
            </div>
         
        </div>
    )



}

