import React , {useState} from 'react';


export const Buttons = (props) => {

    const [hover, setHover] = useState(false);
    const [clickStart, setClickStart] = useState(false);


    const buttonStyle =  {
 
        marginTop: '15px',
        marginBottom: '10px',
        padding: '8px',
        boxSizing: 'border-box',
        outline: 'none',
        fontSize: '14px',
        background: '#fff',
        borderRadius: '5px',
        border: 'none',
        backgroundColor:'#1a73e8',
        color: 'white',
        boxShadow: '0 4px #666',
        minWidth:"150px",
        cursor:'pointer',
        ...props.style,

    }

    const hoverStyle = {
       
        backgroundColor:'#1a55ff',
    }
    


    const mouseDownStyle = {
        transform: 'translateY(2px)',
        boxShadow: '023px #666'

    }
    return (
        <React.Fragment>
            {props.isDefine && 
                <button 
                onMouseEnter={()=>{
                    setHover(true);
                  }}
                  onMouseLeave={()=>{
                    setHover(false);
                  }}
                  onMouseDown = {()=>{
                      setClickStart(true)
                  }}

                    style={{
                        ...buttonStyle,
                        ...(hover ? hoverStyle : null),
                        ...(clickStart ? mouseDownStyle : null)
                    }} 
                    onClick={() => {
                        setClickStart(false)
                        props.click()

                    }
                    } >
                        {props.text}
                </button>
            }
        </React.Fragment>
        
       
    )

}