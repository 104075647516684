import React from 'react';
import { unLog} from '../redux/connection/log.js'
import { useDispatch } from 'react-redux';
import { ButtonInLine } from '../stores/buttonInLine/ButtonInLine';
//importing fontawesome libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import './Deconnexion.css'

library.add(faRightFromBracket);




export const Deconnection = (props) => {

    const dispatch = useDispatch()

    function handle () {
        console.log("unlog")
        dispatch(unLog())
    }

    return(
        <React.Fragment>
            <FontAwesomeIcon className='button-hover-animation' icon={faRightFromBracket} size="2x" color='white' onClick={handle}></FontAwesomeIcon>
        </React.Fragment>
    )
}