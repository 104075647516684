import React from 'react';
import { Deconnection } from '../deconnection/Deconnexion';
import './barreNav.css'

export const BarreNav = (props) => {

    return (
        <nav class="onaos-navbar bg-onaos justify-content-between">
            <span class="onaos-navbar-item">Gestion des conflits</span>
            <div>
                <Deconnection/>
            </div>
      </nav>
    )


}