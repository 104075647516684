import { configureStore } from "@reduxjs/toolkit";
import { logSlice } from "../connection/log";
import { onaosSlice } from "../onaosprojects/onaosprojects";
import { fileSlice } from "../files/files";
import { testSlice } from "../test/test";


export const store = configureStore({
    reducer:{
        log : logSlice.reducer,
        onaosProject: onaosSlice.reducer,
        file: fileSlice.reducer,
        test : testSlice.reducer
    },
});