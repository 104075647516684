import { createSlice } from "@reduxjs/toolkit";



export const testSlice = createSlice({
    name : "test",
    initialState:{
        msg : "", 
    },
    reducers: {
        setMsg : (state, action) => {
            state.msg = action.payload.infos
            return state
        },


       
    }
})


export const {setMsg} = testSlice.actions;