import React , {useEffect} from 'react';
import { useDispatch } from 'react-redux';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,  
  
} from "react-router-dom";

import { useSelector} from 'react-redux';

import './App.css';
import { Dashboard } from './dashboard/dashboard';
import { putAPI } from './api/api';
import { setToken } from './redux/connection/log';

import configData from "./api/configdata.json";
import { closeState } from './redux/onaosprojects/onaosprojects';


export const App = ()=> {

  const log = useSelector( (state) =>state.log ) 
  const dispatch = useDispatch()
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let t = params.get('t');

  let sso = params.get('sso');


  const closeOnaos = () => {
      dispatch(closeState())
  }


  
  useEffect( () => {

  
    if (t !== null && t !== "" && t!== undefined && !log.connect ) {

      const data = {
        token : t,
      }
 
      putAPI("/checkredirect", "", data).then(data => {
        if ( data.token !== undefined && data.token !== ""){
          dispatch(setToken(data))
        } else {
          setTimeout(() => window.open(configData.urlSSOFront + "/?r=" + configData.projetName, '_self'), 200);
        }
        })
      }

      if ( t === null && !log.connect && (sso === undefined || sso === null)) {
        setTimeout(() => window.open(configData.urlSSOFront + "/?r=" + configData.projetName, '_self'), 200);
      }

      
      if (sso !== undefined && sso !== null && !log.connect && t === null  ) {

        const data = {
          token : sso,
          service : configData.projetName
        }
       
        putAPI("/checktoken", "", data).then(data => {
          if (data.token !== undefined && data.token !== null && data.token !== ""){
            dispatch(setToken(data))
          } else {
            setTimeout(() => window.open(configData.urlSSOFront + "/?r=" + configData.projetName, '_self'), 200);
          }
          
        })
      }
  })



  return (
    <div className="App" onClick={closeOnaos}>
        <Router>
            <Routes>
              
            {log.connect &&  <Route path = "/dashboard" element = { <Dashboard/> } /> }
         

              {log.dashboard && log.connect  && 
                <Route 
                  path = "*"
                  element = {<Navigate replace to = "/dashboard" />}
                /> 
              }



              
              



            </Routes>
          </Router>
   

    </div>
  );
}


